	/* global jQuery */
	"use strict";
	/* Instantiate Classes */
	const transitions = new Transitions();
	const loader      = new Loader();
	const nav         = new Nav();
	const ada         = new ADA();
	new Utilities();
	window.loader = loader;

	const iw = {};

	iw.init = {
		mqMd : window.matchMedia("(min-width: 769px)")
	};

	iw.dom = {
		menuToggle : $("#menu-toggle"),
		navMain : $("#nav-main"),
		body : $("body")
	};

	$(window).on("load", function () {
		loader.hide(transitions.showSiteContent());
		iw.dom.body.addClass("loaded");
		const homeSlider = new Swiper('#slider-home',  {
			slidesPerView: 3,
			spaceBetween: 0,
			loop: false,
			speed: 800,
			effect: 'fade',
			simulateTouch: true,
			autoHeight: true,
			autoplay: {
				delay: 6500,
				disableOnInteration: false
			},
			// Navigation arrows
			navigation: {
				nextEl: '.swiper-button-next',
				prevEl: '.swiper-button-prev',
			},

			lazy: {
				loadPrevNext: true
			}
		});
	});

	$(window).on("scroll",function() {
		Utilities.setScrolledClass(iw.dom.body);
	});

	//put all on click stuff in here using some method of targeting
	$(document).on("click", function (e) {
		const $target = $(e.target);
		//if target is main nav link with no drop class
		if ($target.is($("#nav-main a"))) {
			//prevent link default behavior
			e.preventDefault();
			if (!$target.parent().hasClass("drop")) {
				//Page Transition (show loader and then redirect page. showLoader has a callback that is being used to redirect the page)
				loader.show($target,transitions.redirectPage);
			} else {
				$target.parent().toggleClass("active");
			}
		}
		if ($target.is($(".drop").find(">a"))) {
			nav.showNavDropdown($target);
		}
		if ($target.is( iw.dom.menuToggle ) || $target.parents().is(iw.dom.menuToggle)) {
			nav.toggleNav();
			ada.toggleHiddenProps($("#nav-main>ul>li>a"));
		}
	});

	//make nav sticky and work with IE

	if (iw.init.mqMd.matches) { iw.dom.navMain.stickybits(); }

	//Media Query
	iw.init.mqMd.addEventListener("change", function(e)  {
		if (e.matches) {
			iw.dom.navMain.stickybits();
			ada.setADAProps(iw.dom.navMain.find("ul li a"),"show");
			ada.setADAProps(iw.dom.navMain.find("ul ul li a"),"hide");
		} else {
			iw.dom.navMain.stickybits().cleanup();
			ada.setADAProps(iw.dom.navMain.find("ul li a"),"hide");
		}
	},false);

